
import './Ubicacion.scss';
import GoogleMaps from './Googlemaps';
import UbicacionStyles from './Ubicacion.elements';
import mapaTrujillo from '../img-general/trujillo.png';
import mapaLima from '../img-general/lima.png';

const centerTrujillo = {
    lat: -8.1089635,
    lng: -79.0162615,
};
const positionTrujillo = {
    lat: -8.1089635,
    lng: -79.0162615
}
const centerLIma = {
    lat: -11.9916973,
    lng: -77.0145097,
};
const positionLima = {
    lat: -11.9916973,
    lng: -77.0145097,
}

function Ubicacion() {
  return (
    <UbicacionStyles>

        <div className="mapsWrapper">
            <div className="textmap">
                <div className="text">
                    <h2>SEDE - TRUJILLO</h2>
                    <ul>
                        <li>Av. Vallejo 558 Urb. Palermo</li>
                        <li>(044) 204225 / +51 950951451</li>
                        <li>ventas@ozonoraiperu.com</li>
                    </ul>
                </div>
                <div className="map">
                    <GoogleMaps mapa={mapaTrujillo}/>
                </div>
            </div>

            <div className="textmap">
                <div className="text">
                    <h2>SEDE - LIMA</h2>
                    <ul>
                        <li>Jr. Los Amelos 2341 Urb. San Ignacio
                            San juan de Lurigancho (altura Plaza Vea - Las flores)</li>
                        <li>(01) 387 0086 / +51 979751325</li>
                        <li>ventas@ozonoraiperu.com</li>
                    </ul>
                </div>
                <div className="map">
                    <GoogleMaps mapa={mapaLima}/>
                </div>
            </div>

        </div>

    </UbicacionStyles>
  );
}

export default Ubicacion;


