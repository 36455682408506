
const size = {
    phone: "575px",
    tablet: "768px",
    desktop: "1024px",
    lgdesktop: "1440px",
    xldesktop: "2560px"
};

export const device = {
    phone: `(max-width: ${size.phone})`,
    tablet: `(max-width: ${size.tablet})`,
    desktop: `(max-width: ${size.desktop})`,
    lgdesktop: `(max-width: ${size.lgdesktop})`,
    xldesktop: `(max-width: ${size.xldesktop})`
};

