
import img_domestico_osmosis_osmosis_de_100_gpd from './imgs-productos/domestico-osmosis-osmosis-de-100-gpd.webp';
import img_domestico_osmosis_osmosis_de_200_gpd from './imgs-productos/domestico-osmosis-osmosis-de-200-gpd.webp';
import img_domestico_osmosis_osmosis_de_400_gpd from './imgs-productos/domestico-osmosis-osmosis-de-400-gpd.webp';
import img_domestica_agua_purificador_de_agua_grant from './imgs-productos/domestica-agua-purificador-de-agua-grant.webp';
import img_domestica_agua_purificador_de_agua_alargado from './imgs-productos/domestica-agua-purificador-de-agua-alargado.webp';
import img_domestica_agua_purificador_de_agua_flat_plus from './imgs-productos/domestica-agua-purificador-de-agua-flat-plus.webp';
import img_domestica_agua_purificador_de_agua_flat from './imgs-productos/domestica-agua-purificador-de-agua-flat.webp';
import img_domestica_agua_purificador_de_agua_gota_plus from './imgs-productos/domestica-agua-purificador-de-agua-gota-plus.webp';
import img_domestica_agua_purificador_de_agua_mini_acrilico from './imgs-productos/domestica-agua-purificador-de-agua-mini-acrilico.webp';
import img_domestica_agua_purificador_de_agua_mini_curve from './imgs-productos/domestica-agua-purificador-de-agua-mini-curve.webp';
import img_domestica_agua_purificador_de_agua_mixto from './imgs-productos/domestica-agua-purificador-de-agua-mixto.webp';
import img_domestica_agua_purificador_de_agua_y_aire_multiproposito from './imgs-productos/domestica-agua-purificador-de-agua-y-aire-multiproposito.webp';
import img_domestica_aire_purificador_de_aire_acrilico_25_mgrs from './imgs-productos/domestica-aire-purificador-de-aire-acrilico-25-mgrs.webp';
import img_domestica_aire_purificador_de_aire_con_filtro_hepa from './imgs-productos/domestica-aire-purificador-de-aire-con-filtro-hepa.webp';
import img_domestica_aire_purificador_de_aire_con_ozono_y_uv from './imgs-productos/domestica-aire-purificador-de-aire-con-ozono-y-uv.webp';
import img_domestica_aire_purificador_de_aire_mixto_fijo_burbujeo from './imgs-productos/domestica-aire-purificador-de-aire-mixto-fijo-burbujeo.webp';
import img_industrial_agua_filtro_automatico_ablandador from './imgs-productos/industrial-agua-filtro-automatico-ablandador.webp';
import img_industrial_agua_filtro_automatico_de_carbon from './imgs-productos/industrial-agua-filtro-automatico-de-carbon.webp';
import img_industrial_agua_filtro_automatico_multimedia from './imgs-productos/industrial-agua-filtro-automatico-multimedia.webp';
import img_industrial_agua_filtros_pulidores from './imgs-productos/industrial-agua-filtros-pulidores.webp';
import img_industrial_agua_generador_de_ozono from './imgs-productos/industrial-agua-generador-de-ozono.webp';
import img_industrial_agua_osmosis_inversa from './imgs-productos/industrial-agua-osmosis-inversa.webp';
import img_industrial_agua_ultrafiltracion from './imgs-productos/industrial-agua-ultrafiltracion.webp';
import img_industrial_agua_ultravioleta from './imgs-productos/industrial-agua-ultravioleta.webp';
import img_industrial_aire_atomizador from './imgs-productos/industrial-aire-atomizador.webp';
import img_industrial_aire_canon_de_ozono from './imgs-productos/industrial-aire-canon-de-ozono.webp';
import img_industrial_aire_nebulizador from './imgs-productos/industrial-aire-nebulizador.webp';
import img_industrial_aire_purificador_de_aire_para_carro from './imgs-productos/industrial-aire-purificador-de-aire-para-carro.webp';
import img_purificador_aerodinamico from './imgs-productos/purificador-aerodinamico.webp';


const productsData = [
    // ----------------------------------------------------------------------------
    // OSMOSIS DE 100 GPD
    {
        id: 'osmosis-de-100-gpd',
        title: 'OSMOSIS DE 100 GPD',
        subtitle: 'Equipos de osmosis inversa | Línea doméstica',
        category: 'osmosis',           // aire
        line: 'domestica',          // industrial
        images: {
            image1: img_domestico_osmosis_osmosis_de_100_gpd,
            image2: img_domestico_osmosis_osmosis_de_100_gpd,
        },
        description: [
            {
                type: 'h2',
                content: 'DESCRIPCION DEL PROCESO DE OSMOSIS INVERSA',
            },
            {
                type: 'p',
                content: '1° ETAPA.- El agua dura pasa por un filtro de sedimentos que elimina los sólidos en suspensión (tierra, barro, residuos fecales. arena, óxido, etc.) con un tamaño mayor a 5 micras.',
            },
            {
                type: 'p',
                content: '2° ETAPA.- pasa por un filtro de carbón activado granulado que absorbe y elimina el cloro y otros contaminantes químicos (pesticidas, aceites, etc…) hasta en un 99 %.',
            },
            {
                type: 'p',
                content: '3° ETAPA.- El agua sigue su curso por un filtro de carbón activado en bloque. Un polvo fino de carbón es comprimido y unido por un medio adhesivo. Los intrincados poros formados dentro del bloque aseguran el contacto con las impurezas orgánicas, mejorando su eficiencia en la eliminación del mal olor y mal sabor del agua, además de eliminar el cloro totalmente.',
            },
            {
                type: 'p',
                content: '4° ETAPA.- Mediante presión, filtramos el agua a través de una membrana semipermeable hecha con películas de poliamida aromática de tipo micro poroso (0.0001 micras) TECNOLOGIA creada por la NASA'
            },
            {
                type: 'p',
                content: 'Consiste en una fina película semipermeable por donde se conduce el líquido y consigue rechazar en un 90 á 99 % el Total de Sales Disueltas (TDS), es decir plomo, calcio, magnesio, sodio, nitratos, nitritos, hierro, cobre, cadmio, zinc, arsénico y más de 100 distintas sustancias existentes en el agua'
            },
            {
                type: 'p',
                content: '5° ETAPA.- Un pos filtro de carbón activado de cáscara de coco es el último paso, cuya función es oxigenar el AGUA PURA que ha estado almacenada en el depósito presurizado y al mismo tiempo añadir un mínimo de minerales y dar una calidad de agua perfecta para el paladar, eliminando cualquier mal sabor residual, garantizando una máxima calidad del agua.',
            },
            {
                type: 'p',
                content: 'Con tanque de 3.2 glnes.'
            }
        ],
        details: [
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'Marca: Hidrotek',
                    },
                    {
                        type: 'li',
                        content: 'Voltaje de entrada: 220v, 100w.',
                    },
                    {
                        type: 'li',
                        content: 'Con 1 membranas de 100 GPD. c/u',
                    },
                    {
                        type: 'li',
                        content: 'Vida útil del purificador: de 15 a 20 años.',
                    },
                    {
                        type: 'li',
                        content: 'Material de Estructura básica metálica.',
                    },
                    {
                        type: 'li',
                        content: 'Filtro de carbón activado en bloque de 2.5”x10”.',
                    },
                    {
                        type: 'li',
                        content: 'Filtro de carbón activado granulado de 2.5”x10”',
                    },
                    {
                        type: 'li',
                        content: 'Filtro de sedimentos de 2,5×10”.',
                    },
                    {
                        type: 'li',
                        content: 'Pos filtro de carbón granulado.',
                    },
                    {
                        type: 'li',
                        content: 'Capacidad de Prod. de agua: 15 Lts/hr.',
                    },
                    {
                        type: 'li',
                        content: '1 caño o salida.',
                    },
                    {
                        type: 'li',
                        content: 'Con tanque hidroneumático de 3.2 glnes.',
                    }
                ]
            }
        ],
    },
    // ----------------------------------------------------------------------------
    // OSMOSIS DE 200 GPD
    {
        id: 'osmosis-de-200-gpd',
        title: 'OSMOSIS DE 200 GPD',
        subtitle: 'Equipos de osmosis inversa | Línea doméstica',
        category: 'osmosis',        // aire
        line: 'domestica',          // industrial
        images: {
            image1: img_domestico_osmosis_osmosis_de_200_gpd,
            image2: img_domestico_osmosis_osmosis_de_200_gpd,
        },
        description: [
            {
                type: 'h2',
                content: 'DESCRIPCION DEL PROCESO DE OSMOSIS INVERSA',
            },
            {
                type: 'p',
                content: '1° ETAPA.- El agua dura pasa por un filtro de sedimentos que elimina los sólidos en suspensión (tierra, barro, residuos fecales. arena, óxido, etc.) con un tamaño mayor a 5 micras.',
            },
            {
                type: 'p',
                content: '2° ETAPA.- pasa por un filtro de carbón activado granulado que absorbe y elimina el cloro y otros contaminantes químicos (pesticidas, aceites, etc…) hasta en un 99 %.',
            },
            {
                type: 'p',
                content: '3° ETAPA.- El agua sigue su curso por un filtro de carbón activado en bloque. Un polvo fino de carbón es comprimido y unido por un medio adhesivo. Los intrincados poros formados dentro del bloque aseguran el contacto con las impurezas orgánicas, mejorando su eficiencia en la eliminación del mal olor y mal sabor del agua, además de eliminar el cloro totalmente.',
            },
            {
                type: 'p',
                content: `4° ETAPA.- Mediante presión, filtramos el agua a través de una membrana semipermeable hecha con películas de poliamida aromática de tipo micro poroso (0.0001 micras) TECNOLOGIA creada por la NASA.
                Consiste en una fina película semipermeable por donde se conduce el líquido y consigue rechazar en un 90 á 99 % el Total de Sales Disueltas (TDS), es decir plomo, calcio, magnesio, sodio, nitratos, nitritos, hierro, cobre, cadmio, zinc, arsénico y más de 100 distintas sustancias existentes en el agua`,
            },
            {
                type: 'p',
                content: '5° ETAPA.- Un pos filtro de carbón activado de cáscara de coco es el último paso, cuya función es oxigenar el AGUA PURA que ha estado almacenada en el depósito presurizado y al mismo tiempo añadir un mínimo de minerales y dar una calidad de agua perfecta para el paladar, eliminando cualquier mal sabor residual, garantizando una máxima calidad del agua.',
            },
        ],
        details: [
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'Marca: Hidrotek'
                    },
                    {
                        type: 'li',
                        content: 'Voltaje de entrada: 220v, 100w.'
                    },
                    {
                        type: 'li',
                        content: 'Con 1 membranas de 200 GPD.'
                    },
                    {
                        type: 'li',
                        content: 'Vida útil del purificador: de 15 a 20 años.'
                    },
                    {
                        type: 'li',
                        content: 'Material de Estructura básica metálica.'
                    },
                    {
                        type: 'li',
                        content: 'Filtro de carbón activado en bloque de 2.5”x10”.'
                    },
                    {
                        type: 'li',
                        content: 'Filtro de carbón activado granulado de 2.5”x10”'
                    },
                    {
                        type: 'li',
                        content: 'Filtro de sedimentos de 2,5×10”.'
                    },
                    {
                        type: 'li',
                        content: 'Pos filtro de carbón granulado.'
                    },
                    {
                        type: 'li',
                        content: 'Capacidad de Prod. de agua: 30 Lts/hr.'
                    },
                    {
                        type: 'li',
                        content: '1 caño o salida.'
                    },
                    {
                        type: 'li',
                        content: 'Sin tanque hidroneumático'
                    }
                ]
            }
        ],
    },
    // ----------------------------------------------------------------------------
    // OSMOSIS DE 400 GPD
    {
        id: 'osmosis-de-400-gpd',
        title: 'OSMOSIS DE 400 GPD',
        subtitle: 'Equipos de osmosis inversa | Línea doméstica',
        category: 'osmosis',        // aire
        line: 'domestica',          // industrial
        images: {
            image1: img_domestico_osmosis_osmosis_de_400_gpd,
            image2: img_domestico_osmosis_osmosis_de_400_gpd,
        },
        description: [
            {
                type: 'h2',
                content: 'DESCRIPCION DEL PROCESO DE OSMOSIS INVERSA',
            },
            {
                type: 'p',
                content: '1° ETAPA.- El agua dura pasa por un filtro de sedimentos que elimina los sólidos en suspensión (tierra, barro, residuos fecales. arena, óxido, etc.) con un tamaño mayor a 5 micras.',
            },
            {
                type: 'p',
                content: '2° ETAPA.- pasa por un filtro de carbón activado granulado que absorbe y elimina el cloro y otros contaminantes químicos (pesticidas, aceites, etc…) hasta en un 99 %.',
            },
            {
                type: 'p',
                content: '3° ETAPA.- El agua sigue su curso por un filtro de carbón activado en bloque. Un polvo fino de carbón es comprimido y unido por un medio adhesivo. Los intrincados poros formados dentro del bloque aseguran el contacto con las impurezas orgánicas, mejorando su eficiencia en la eliminación del mal olor y mal sabor del agua, además de eliminar el cloro totalmente.',
            },
            {
                type: 'p',
                content: `4° ETAPA.- Mediante presión, filtramos el agua a través de una membrana semipermeable hecha con películas de poliamida aromática de tipo micro poroso (0.0001 micras) TECNOLOGIA creada por la NASA.
                Consiste en una fina película semipermeable por donde se conduce el líquido y consigue rechazar en un 90 á 99 % el Total de Sales Disueltas (TDS), es decir plomo, calcio, magnesio, sodio, nitratos, nitritos, hierro, cobre, cadmio, zinc, arsénico y más de 100 distintas sustancias existentes en el agua`,
            },
            {
                type: 'p',
                content: '5° ETAPA.- Un pos filtro de carbón activado de cáscara de coco es el último paso, cuya función es oxigenar el AGUA PURA que ha estado almacenada en el depósito presurizado y al mismo tiempo añadir un mínimo de minerales y dar una calidad de agua perfecta para el paladar, eliminando cualquier mal sabor residual, garantizando una máxima calidad del agua.',
            },
        ],
        details: [
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'Marca: Hidrotek'
                    },
                    {
                        type: 'li',
                        content: 'Voltaje de entrada: 220v, 100w.'
                    },
                    {
                        type: 'li',
                        content: 'Con 2 membranas de 200 GPD.'
                    },
                    {
                        type: 'li',
                        content: 'Vida útil del purificador: de 15 a 20 años.'
                    },
                    {
                        type: 'li',
                        content: 'Material de Estructura básica metálica.'
                    },
                    {
                        type: 'li',
                        content: 'Filtro de carbón activado en bloque de 2.5”x10”.'
                    },
                    {
                        type: 'li',
                        content: 'Filtro de carbón activado granulado de 2.5”x10”'
                    },
                    {
                        type: 'li',
                        content: 'Filtro de sedimentos de 2,5×10”.'
                    },
                    {
                        type: 'li',
                        content: 'Pos filtro de carbón granulado.'
                    },
                    {
                        type: 'li',
                        content: 'Capacidad de Prod. de agua: 60 Lts/hr.'
                    },
                    {
                        type: 'li',
                        content: '1 caño o salida.'
                    },
                    {
                        type: 'li',
                        content: 'Sin tanque hidroneumático'
                    }
                ]
            }
        ],
    },
    // ----------------------------------------------------------------------------
    // PURIFICADOR DE AGUA Y AIRE MULTIPROPÓSITO
    {
        id: 'purificador-de-agua-y-aire-multiproposito',
        title: 'PURIFICADOR DE AGUA Y AIRE MULTIPROPÓSITO',
        subtitle: 'Línea doméstica | Purificadores de agua y aire',
        category: 'aguaaire',       // aire
        line: 'domestica',          // industrial
        images: {
            image1: img_domestica_agua_purificador_de_agua_y_aire_multiproposito,
            image2: img_domestica_agua_purificador_de_agua_y_aire_multiproposito,
        },
        description: [
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'Hecho en acrilico ABS.',
                    },
                    {
                        type: 'li',
                        content: 'Alimentacion de energia...220v , 60Hz.',
                    },
                    {
                        type: 'li',
                        content: 'Consumo: 15 w.'
                    },
                    {
                        type: 'li',
                        content: 'Medidas: 35x23x10cm.'
                    },
                    {
                        type: 'li',
                        content: 'Capacidad de ozonizacion: 500mgrs/hr.'
                    },
                    {
                        type: 'li',
                        content: 'Programable: 5 , 10, 20, 30 min.'
                    },
                    {
                        type: 'li',
                        content: 'Recomendado para usar con agua filtrada. '
                    },
                    {
                        type: 'li',
                        content: 'Ozonizacion por burbujeo al agua.'
                    },
                    {
                        type: 'li',
                        content: 'Ozonizacion directa al aire.'
                    },
                    {
                        type: 'li',
                        content: 'Al ozonizar el agua nos permite desinfectar todo tipo de alimentos.'
                    },
                    {
                        type: 'li',
                        content: 'El ozono en el aire elimina la población microbiológica.'
                    },
                    {
                        type: 'li',
                        content: 'Recomendado para ambientes medianos y pequeños.'
                    },
                    {
                        type: 'li',
                        content: 'Fácil de usar.'
                    }
                ]
            }
        ],
        details: [
        ],
    },

    // ----------------------------------------------------------------------------
    // PURIFICADOR ALARGADO
    {
        id: 'alargado',
        title: 'PURIFICADOR ALARGADO',
        subtitle: 'Línea doméstica | Purificadores de agua',
        category: 'agua',        // aire
        line: 'domestica',          // industrial
        images: {
            image1: img_domestica_agua_purificador_de_agua_alargado,
            image2: img_domestica_agua_purificador_de_agua_alargado,
        },
        description: [
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'HECHO EN ACERO INOXIDABLE.',
                    },
                    {
                        type: 'li',
                        content: 'CAÑO GIRATORIO.',
                    },
                    {
                        type: 'li',
                        content: 'MEDIDAS: 31cm. x 15cm. x 9 cm.',
                    },
                    {
                        type: 'li',
                        content: 'GENERA 25 mgrs/hr DE OZONO.',
                    },
                    {
                        type: 'li',
                        content: 'ELIMINA IMPUREZA FISICA.',
                    },
                    {
                        type: 'li',
                        content: 'ELIMINA EL CLORO, MAL SABOR Y MAL OLOR DEL AGUA.',
                    },
                    {
                        type: 'li',
                        content: 'EL OZONO ES INYECTADO AL AGUA PARA DESINFECTARLA.',
                    },
                    {
                        type: 'li',
                        content: 'EL AGUA OZONIZADA NOS PERMITE DESINFECTAR MULTIPLES ALIMENTOS.',
                    },
                    {
                        type: 'li',
                        content: 'NOS PRODUCE AGUA PURIFICADA AL INSTANTE, LAS 24 HORAS DEL DIA, LISTA PARA BEBER DIRECTAMENTE.',
                    },
                    {
                        type: 'li',
                        content: 'PODEMOS OBTENER AGUA LIGERAMENTE OZONIZADA Y AGUA OZONIZADA CONCENTRADA.',
                    },
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // PURIFICADOR GRANT
    {
        id: 'grant',
        title: 'PURIFICADOR GRANT',
        subtitle: 'Línea doméstica | Purificadores de agua',
        category: 'agua',        // aire
        line: 'domestica',          // industrial
        images: {
            image1: img_domestica_agua_purificador_de_agua_grant,
            image2: img_domestica_agua_purificador_de_agua_grant,
        },
        description: [
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'HECHO EN ACERO INOXIDABLE.',
                    },
                    {
                        type: 'li',
                        content: 'CAÑO GIRATORIO.',
                    },
                    {
                        type: 'li',
                        content: 'MEDIDAS: 29cm. x 15cm. x 8cm.',
                    },
                    {
                        type: 'li',
                        content: 'GENERA 25 mgrs/hr DE OZONO.',
                    },
                    {
                        type: 'li',
                        content: 'ELIMINA IMPUREZA FISICA.',
                    },
                    {
                        type: 'li',
                        content: 'ELIMINA EL CLORO, MAL SABOR Y MAL OLOR DEL AGUA.',
                    },
                    {
                        type: 'li',
                        content: 'EL OZONO ES INYECTADO AL AGUA PARA DESINFECTARLA.',
                    },
                    {
                        type: 'li',
                        content: 'EL AGUA OZONIZADA NOS PERMITE DESINFECTAR MULTIPLES ALIMENTOS.',
                    },
                    {
                        type: 'li',
                        content: 'NOS PRODUCE AGUA PURIFICADA AL INSTANTE, LAS 24 HORAS DEL DIA, LISTA PARA BEBER DIRECTAMENTE.',
                    },
                    {
                        type: 'li',
                        content: 'PODEMOS OBTENER AGUA LIGERAMENTE OZONIZADA Y AGUA OZONIZADA CONCENTRADA.',
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // PURIFICADOR FLAT
    {
        id: 'flat',
        title: 'PURIFICADOR FLAT',
        subtitle: 'Línea doméstica | Purificadores de agua',
        category: 'agua',        // aire
        line: 'domestica',          // industrial
        images: {
            image1: img_domestica_agua_purificador_de_agua_flat,
            image2: img_domestica_agua_purificador_de_agua_flat,
        },
        description: [
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'HECHO EN ACERO INOXIDABLE.',
                    },
                    {
                        type: 'li',
                        content: 'CAÑO GIRATORIO.',
                    },
                    {
                        type: 'li',
                        content: 'MEDIDAS: 30cm. x 14cm. x 10cm.',
                    },
                    {
                        type: 'li',
                        content: 'GENERA 25 mgrs/hr DE OZONO.',
                    },
                    {
                        type: 'li',
                        content: 'ELIMINA IMPUREZA FISICA.',
                    },
                    {
                        type: 'li',
                        content: 'ELIMINA EL CLORO, MAL SABOR Y MAL OLOR DEL AGUA.',
                    },
                    {
                        type: 'li',
                        content: 'EL OZONO ES INYECTADO AL AGUA PARA DESINFECTARLA.',
                    },
                    {
                        type: 'li',
                        content: 'EL AGUA OZONIZADA NOS PERMITE DESINFECTAR MULTIPLES ALIMENTOS.',
                    },
                    {
                        type: 'li',
                        content: 'NOS PRODUCE AGUA PURIFICADA AL INSTANTE, LAS 24 HORAS DEL DIA, LISTA PARA BEBER DIRECTAMENTE.',
                    },
                    {
                        type: 'li',
                        content: 'PODEMOS OBTENER AGUA LIGERAMENTE OZONIZADA Y AGUA OZONIZADA CONCENTRADA.',
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // PURIFICADOR MINI CURVE
    {
        id: 'mini-curve',
        title: 'PURIFICADOR MINI CURVE',
        subtitle: 'Línea doméstica | Purificadores de agua',
        category: 'agua',        // aire
        line: 'domestica',          // industrial
        images: {
            image1: img_domestica_agua_purificador_de_agua_mini_curve,
            image2: img_domestica_agua_purificador_de_agua_mini_curve,
        },
        description: [
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'HECHO EN ACERO INOXIDABLE.',
                    },
                    {
                        type: 'li',
                        content: 'CAÑO GIRATORIO.',
                    },
                    {
                        type: 'li',
                        content: 'MEDIDAS: 30cm. x 14cm. x 10cm.',
                    },
                    {
                        type: 'li',
                        content: 'GENERA 25 mgrs/hr DE OZONO.',
                    },
                    {
                        type: 'li',
                        content: 'ELIMINA IMPUREZA FISICA.',
                    },
                    {
                        type: 'li',
                        content: 'ELIMINA EL CLORO, MAL SABOR Y MAL OLOR DEL AGUA.',
                    },
                    {
                        type: 'li',
                        content: 'EL OZONO ES INYECTADO AL AGUA PARA DESINFECTARLA.',
                    },
                    {
                        type: 'li',
                        content: 'EL AGUA OZONIZADA NOS PERMITE DESINFECTAR MULTIPLES ALIMENTOS.',
                    },
                    {
                        type: 'li',
                        content: 'NOS PRODUCE AGUA PURIFICADA AL INSTANTE, LAS 24 HORAS DEL DIA, LISTA PARA BEBER DIRECTAMENTE.',
                    },
                    {
                        type: 'li',
                        content: 'PODEMOS OBTENER AGUA LIGERAMENTE OZONIZADA Y AGUA OZONIZADA CONCENTRADA.',
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // PURIFICADOR FLAT PLUS
    {
        id: 'flat-plus',
        title: 'PURIFICADOR FLAT PLUS',
        subtitle: 'Línea doméstica | Purificadores de agua',
        category: 'agua',           // aire
        line: 'domestica',          // industrial
        images: {
            image1: img_domestica_agua_purificador_de_agua_flat_plus,
            image2: img_domestica_agua_purificador_de_agua_flat_plus,
        },
        description: [
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'HECHO EN ACERO INOXIDABLE.',
                    },
                    {
                        type: 'li',
                        content: 'CAÑO GIRATORIO.',
                    },
                    {
                        type: 'li',
                        content: 'MEDIDAS: 30 X 13 X 11cm.',
                    },
                    {
                        type: 'li',
                        content: 'GENERA 100 mgrs/hr DE OZONO.',
                    },
                    {
                        type: 'li',
                        content: 'ELIMINA IMPUREZA FISICA.',
                    },
                    {
                        type: 'li',
                        content: 'ELIMINA EL CLORO, MAL SABOR Y MAL OLOR DEL AGUA.',
                    },
                    {
                        type: 'li',
                        content: 'EL OZONO ES INYECTADO AL AGUA PARA DESINFECTARLA.',
                    },
                    {
                        type: 'li',
                        content: 'EL AGUA OZONIZADA NOS PERMITE DESINFECTAR MULTIPLES ALIMENTOS.',
                    },
                    {
                        type: 'li',
                        content: 'NOS PRODUCE AGUA PURIFICADA AL INSTANTE, LAS 24 HORAS DEL DIA, LISTA PARA BEBER DIRECTAMENTE.',
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // PURIFICADOR GOTA PLUS
    {
        id: 'gota-plus',
        title: 'PURIFICADOR GOTA PLUS',
        subtitle: 'Línea doméstica | Purificadores de agua',
        category: 'agua',           // aire
        line: 'domestica',          // industrial
        images: {
            image1: img_domestica_agua_purificador_de_agua_gota_plus,
            image2: img_domestica_agua_purificador_de_agua_gota_plus,
        },
        description: [
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'HECHO EN ACERO INOXIDABLE.',
                    },
                    {
                        type: 'li',
                        content: 'CAÑO GIRATORIO.',
                    },
                    {
                        type: 'li',
                        content: 'MEDIDAS: 26 x 19 x 8cm',
                    },
                    {
                        type: 'li',
                        content: 'GENERA 100 mgrs/hr DE OZONO.',
                    },
                    {
                        type: 'li',
                        content: 'ELIMINA IMPUREZA FISICA.',
                    },
                    {
                        type: 'li',
                        content: 'ELIMINA EL CLORO, MAL SABOR Y MAL OLOR DEL AGUA.',
                    },
                    {
                        type: 'li',
                        content: 'EL OZONO ES INYECTADO AL AGUA PARA DESINFECTARLA.',
                    },
                    {
                        type: 'li',
                        content: 'EL AGUA OZONIZADA NOS PERMITE DESINFECTAR MULTIPLES ALIMENTOS.',
                    },
                    {
                        type: 'li',
                        content: 'NOS PRODUCE AGUA PURIFICADA AL INSTANTE, LAS 24 HORAS DEL DIA, LISTA PARA BEBER DIRECTAMENTE.',
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // PURIFICADOR MINI ACRILICO
    {
        id: 'mini-acrilico',
        title: 'PURIFICADOR MINI ACRILICO',
        subtitle: 'Línea doméstica | Purificadores de agua',
        category: 'agua',           // aire
        line: 'domestica',          // industrial
        images: {
            image1: img_domestica_agua_purificador_de_agua_mini_acrilico,
            image2: img_domestica_agua_purificador_de_agua_mini_acrilico,
        },
        description: [
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'HECHO EN ACRILICO-ABS.',
                    },
                    {
                        type: 'li',
                        content: 'CAÑO GIRATORIO.',
                    },
                    {
                        type: 'li',
                        content: 'MEDIDAS: 22cm. x 16cm. x 9 cm.',
                    },
                    {
                        type: 'li',
                        content: 'GENERA 25 mgrs/hr DE OZONO.',
                    },
                    {
                        type: 'li',
                        content: 'ELIMINA IMPUREZA FISICA.',
                    },
                    {
                        type: 'li',
                        content: 'ELIMINA EL CLORO, MAL SABOR Y MAL OLOR DEL AGUA.',
                    },
                    {
                        type: 'li',
                        content: 'EL OZONO ES INYECTADO AL AGUA PARA DESINFECTARLA.',
                    },
                    {
                        type: 'li',
                        content: 'EL AGUA OZONIZADA NOS PERMITE DESINFECTAR MULTIPLES ALIMENTOS.',
                    },
                    {
                        type: 'li',
                        content: 'NOS PRODUCE AGUA PURIFICADA AL INSTANTE, LAS 24 HORAS DEL DIA, LISTA PARA BEBER DIRECTAMENTE.',
                    },
                    {
                        type: 'li',
                        content: 'PODEMOS OBTENER AGUA LIGERAMENTE OZONIZADA Y AGUA OZONIZADA CONCENTRADA.',
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // PURIFICADOR AERODINAMICO
    {
        id: 'aerodinamico',
        title: 'PURIFICADOR AERODINAMICO',
        subtitle: 'Línea doméstica | Purificadores de agua',
        category: 'agua',           // aire
        line: 'domestica',          // industrial
        images: {
            image1: img_purificador_aerodinamico,
            image2: img_purificador_aerodinamico,
        },
        description: [
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'HECHO EN ACRILICO-ABS.',
                    },
                    {
                        type: 'li',
                        content: 'CAÑO GIRATORIO.',
                    },
                    {
                        type: 'li',
                        content: 'MEDIDAS: 31cm. x 20cm. x 15 cm.',
                    },
                    {
                        type: 'li',
                        content: 'GENERA 25 mgrs/hr DE OZONO.',
                    },
                    {
                        type: 'li',
                        content: 'ELIMINA IMPUREZA FISICA.',
                    },
                    {
                        type: 'li',
                        content: 'ELIMINA EL CLORO, MAL SABOR Y MAL OLOR DEL AGUA.',
                    },
                    {
                        type: 'li',
                        content: 'EL OZONO ES INYECTADO AL AGUA PARA DESINFECTARLA.',
                    },
                    {
                        type: 'li',
                        content: 'EL AGUA OZONIZADA NOS PERMITE DESINFECTAR MULTIPLES ALIMENTOS.',
                    },
                    {
                        type: 'li',
                        content: 'NOS PRODUCE AGUA PURIFICADA AL INSTANTE, LAS 24 HORAS DEL DIA, LISTA PARA BEBER DIRECTAMENTE.',
                    },
                    {
                        type: 'li',
                        content: 'PODEMOS OBTENER AGUA LIGERAMENTE OZONIZADA Y AGUA OZONIZADA CONCENTRADA.',
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // PURIFICADOR DE AGUA MIXTO
    {
        id: 'purificador-de-agua-mixto',
        title: 'PURIFICADOR DE AGUA MIXTO',
        subtitle: 'Línea doméstica | Purificadores de agua',
        category: 'agua',           // aire
        line: 'domestica',          // industrial
        images: {
            image1: img_domestica_agua_purificador_de_agua_mixto,
            image2: img_domestica_agua_purificador_de_agua_mixto,
        },
        description: [
            {
                type: 'p',
                content: 'Purificador de agua con doble sistema de ozonización:'
            },
            {
                type: 'p',
                content: 'Por inyección venturi y burbujeo.'
            },
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'HECHO EN ACRILICO-ABS.',
                    },
                    {
                        type: 'li',
                        content: 'CAÑO FIJO.',
                    },
                    {
                        type: 'li',
                        content: 'MEDIDAS: 28 X 18 X 10cm',
                    },
                    {
                        type: 'li',
                        content: 'GENERA 100 mgrs/hr DE OZONO.',
                    },
                    {
                        type: 'li',
                        content: 'ELIMINA IMPUREZA FISICA.',
                    },
                    {
                        type: 'li',
                        content: 'ELIMINA EL CLORO, MAL SABOR Y MAL OLOR DEL AGUA.',
                    },
                    {
                        type: 'li',
                        content: 'EL OZONO ES INYECTADO AL AGUA PARA DESINFECTARLA.',
                    },
                    {
                        type: 'li',
                        content: 'SISTEMA DE OZONIZACIÓN POR BURBUJEO INDEPENDIENTE.',
                    },
                    {
                        type: 'li',
                        content: 'EL AGUA OZONIZADA NOS PERMITE DESINFECTAR MULTIPLES ALIMENTOS.',
                    },
                    {
                        type: 'li',
                        content: 'NOS PRODUCE AGUA PURIFICADA AL INSTANTE, LAS 24 HORAS DEL DIA, LISTA PARA BEBER DIRECTAMENTE.',
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // PURIFICADOR DE AIRE MIXTO FIJO – BURBUJEO
    {
        id: 'purificador-de-aire-mixto-fijo-burbujeo',
        title: 'PURIFICADOR DE AIRE MIXTO FIJO – BURBUJEO',
        subtitle: 'Línea doméstica | Purificadores de aire',
        category: 'aire',           // aire
        line: 'domestica',          // industrial
        images: {
            image1: img_domestica_aire_purificador_de_aire_mixto_fijo_burbujeo,
            image2: img_domestica_aire_purificador_de_aire_mixto_fijo_burbujeo,
        },
        description: [
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // PURIFICADOR DE AIRE ACRILICO 25 MGRS
    {
        id: 'purificador-de-aire-acrilico-25-mgrs',
        title: 'PURIFICADOR DE AIRE ACRILICO 25 MGRS',
        subtitle: 'Línea doméstica | Purificadores de aire',
        category: 'aire',           // aire
        line: 'domestica',          // industrial
        images: {
            image1: img_domestica_aire_purificador_de_aire_acrilico_25_mgrs,
            image2: img_domestica_aire_purificador_de_aire_acrilico_25_mgrs,
        },
        description: [
            {
                type: 'p',
                content: 'Esterilización del aire a través de la generación de ozono, el que permite devolver al ambiente un aire totalmente puro, libre de cualquier micro organismo. El equipo ozonizador elimina malos olores y purifica el ambiente, saturado por toxinas, dejando el aire saludable, fresco y oxigenado, al realizar una acción antimicrobiana que elimina toda clase de bacterias, virus y otros microorganismos patógenos causantes de enfermedades como bronquitis, alergias y procesos asmáticos.'
            },
            {
                type: 'h2',
                content: 'Beneficios:'
            },
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'Relaja el Sistema nervioso.'
                    },
                    {
                        type: 'li',
                        content: 'Alivia la pesadez mental.'
                    },
                    {
                        type: 'li',
                        content: 'Excelente contra resfriados, bronquitis y alergias respiratorias.'
                    },
                    {
                        type: 'li',
                        content: 'Resuelve el problema del aire contaminado.'
                    },
                    {
                        type: 'li',
                        content: 'Posee cualidades desodorizantes, desinfectantes y germicidas.'
                    },
                    {
                        type: 'li',
                        content: 'Crea un ambiente saludable y confortable.'
                    }
                ]
            },
            {
                type: 'h2',
                content: 'Características:'
            },
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'Voltaje de entrada: 220v'
                    },
                    {
                        type: 'li',
                        content: 'Capacidad de ozonización: 25mg/h'
                    },
                    {
                        type: 'li',
                        content: 'Mínimo consumo de energía.25w'
                    },
                    {
                        type: 'li',
                        content: 'Funcionamiento con temporizador.'
                    },
                    {
                        type: 'li',
                        content: 'Apto para todo tipo de ambiente: 40 m3 en promedio'
                    },
                    {
                        type: 'li',
                        content: 'No posee contraindicaciones para la salud humana.'
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // PURIFICADOR DE AIRE CON FILTRO HEPA
    {
        id: 'purificador-de-aire-con-filtro-hepa',
        title: 'PURIFICADOR DE AIRE CON FILTRO HEPA',
        subtitle: 'Línea doméstica | Purificadores de aire',
        category: 'aire',           // aire
        line: 'domestica',          // industrial
        images: {
            image1: img_domestica_aire_purificador_de_aire_con_filtro_hepa,
            image2: img_domestica_aire_purificador_de_aire_con_filtro_hepa,
        },
        description: [
            {
                type: 'h2',
                content: 'FORMA DE FUNCIONAMIENTO:'
            },
            {
                type: 'p',
                content: 'Sistema de limpieza y purificación integral del aire. El equipo trabaja en dos etapas que pueden combinarse o funcionar independientemente cada una.'
            },
            {
                type: 'p',
                content: 'Limpieza del aire a través de un sistema de filtros HEPA que permiten limpiar el aire de impurezas de todo tipo y retienen los principales alérgenos que flotan en el ambiente. El equipo posee 1 filtro de 4 etapas de acuerdo a las siguientes características:'
            },
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'Filtro previo, para separar suciedad y partículas grandes.'
                    },
                    {
                        type: 'li',
                        content: 'Filtro HEPA “High Effucuebct Particle Arresting” (recogedor de partículas de alta eficiencia) puede retirar la mayoría de partículas perjudiciales, incluyendo las esporas de moho, el polvo, los ácaros del polvo, la caspa de las mascotas y otros elementos irritantes del aire. El filtro HEPA consiste en una fibra de cristal de capa multi-entrelazado y que puede eliminar suciedad menor a 5 micras, logrando una purificación de 99.99%.'
                    },
                    {
                        type: 'li',
                        content: 'Filtro de carbón activado cuya función es quitar las partículas que generan el mal olor en especial el humo de cigarrillo.'
                    },
                    {
                        type: 'li',
                        content: 'Filtro especial resistente para la eliminación de bacterias.'
                    },
                    {
                        type: 'li',
                        content: 'Espacio a cubrir: 150 a 200 m3.'
                    },
                    {
                        type: 'li',
                        content: 'Tiempo de Funcionamiento : Programable (1,2,4 y 8 horas)'
                    },
                    {
                        type: 'li',
                        content: 'Capacidad de Ozonización: 500mg/h.'
                    },
                    {
                        type: 'li',
                        content: 'Funcionamiento a control remoto y manual'
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // PURIFICADOR DE AIRE CON OZONO Y UV
    {
        id: 'purificador-de-aire-con-ozono-y-uv',
        title: 'PURIFICADOR DE AIRE CON OZONO Y UV',
        subtitle: 'Línea doméstica | Purificadores de aire',
        category: 'aire',           // aire
        line: 'domestica',          // industrial
        images: {
            image1: img_domestica_aire_purificador_de_aire_con_ozono_y_uv,
            image2: img_domestica_aire_purificador_de_aire_con_ozono_y_uv,
        },
        description: [
            {
                type: 'h2',
                content: 'FUNCIONAMIENTO INTERNO:'
            },
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'PRE FILTRO.'
                    },
                    {
                        type: 'li',
                        content: 'FILTRO HEPA.'
                    },
                    {
                        type: 'li',
                        content: 'RAYOS ULTRAVIOLETA.'
                    },
                    {
                        type: 'li',
                        content: 'FILTRO DE CARBON ACTIVADO.'
                    },
                    {
                        type: 'li',
                        content: 'GENERADOR DE IONES.'
                    },
                    {
                        type: 'li',
                        content: 'GENERADOR DE OZONO.'
                    }
                ]
            },
            {
                type: 'h2',
                content: 'POR QUÉ USAR UN PURIFICADOR DE AIRE:'
            },
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'Elimina los malos olores del aire en el interior de habitaciones y salas medianas y pequeñas.'
                    },
                    {
                        type: 'li',
                        content: 'Erradica los agentes contaminantes del aire tales como:'
                    },
                    {
                        type: 'ul',
                        content: [
                            {
                                type: 'li',
                                content: 'Bacterias.'
                            },
                            {
                                type: 'li',
                                content: 'Virus.'
                            },
                            {
                                type: 'li',
                                content: 'Hongos.'
                            },
                            {
                                type: 'li',
                                content: 'COV (compuestos orgánicos volátiles).'
                            },
                            {
                                type: 'li',
                                content: 'Ácaros.'
                            },
                            {
                                type: 'li',
                                content: 'Polen.'
                            },
                            {
                                type: 'li',
                                content: 'Partículas de Polvo.'
                            }
                        ]
                    }
                ]
            }
        ],
        details: [
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'Marca: OZONORAI'
                    },
                    {
                        type: 'li',
                        content: 'Voltaje: 220v / 60Hz.'
                    },
                    {
                        type: 'li',
                        content: 'Peso: 4 kgrs.'
                    },
                    {
                        type: 'li',
                        content: 'Potencia Nominal: 42 w'
                    },
                    {
                        type: 'li',
                        content: 'Iones Negativos: < 8×106 iones/cm3'
                    },
                    {
                        type: 'li',
                        content: 'Caudal de Aire Máximo: 230m3/hr'
                    },
                    {
                        type: 'li',
                        content: 'Área Cubierta: < 60m2'
                    },
                    {
                        type: 'li',
                        content: 'Eficiencia HEPA: > 99,9%'
                    },
                    {
                        type: 'li',
                        content: 'Ambiente de Trabajo: Temperatura de 0 á 45°'
                    }
                ]
            }
        ],
    },
    // ----------------------------------------------------------------------------
    // FILTRO AUTOMATICO MULTIMEDIA
    {
        id: 'filtro-automatico-multimedia',
        title: 'FILTRO AUTOMATICO MULTIMEDIA',
        subtitle: 'Tratamiento de agua | Línea industrial',
        category: 'agua',           // aire
        line: 'industrial',         // industrial
        images: {
            image1: img_industrial_agua_filtro_automatico_multimedia,
            image2: img_industrial_agua_filtro_automatico_multimedia,
        },
        description: [
            {
                type: 'p',
                content: 'Los filtros multimedia, multi cama o también llamados de lecho profundo, tienen la finalidad de remover sólidos suspendidos en el agua de tamaños de hasta 20 micras. Esto quiere decir que todo sólido en suspensión (tierra, polen, basuras pequeñas y toda impureza física) mayor a 20 micras quedará retenido en el filtro para después ser desechado por el drenaje en el retro lavado; no permitiendo de esta forma que estos sólidos suspendidos pasen al torrente de servicio.'
            },
            {
                type: 'h2',
                content: 'PRESENTACIONES EN:'
            },
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: '1 PIE3'
                    },
                    {
                        type: 'li',
                        content: '2 PIES3'
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // FILTRO AUTOMÁTICO DE CARBON
    {
        id: 'filtro-automatico-de-carbon',
        title: 'FILTRO AUTOMÁTICO DE CARBON',
        subtitle: 'Tratamiento de agua | Línea industrial',
        category: 'agua',           // aire
        line: 'industrial',         // industrial
        images: {
            image1: img_industrial_agua_filtro_automatico_de_carbon,
            image2: img_industrial_agua_filtro_automatico_de_carbon,
        },
        description: [
            {
                type: 'p',
                content: 'Son sistemas de purificación de agua que se utilizan para filtrar contaminantes tales como el cloro, disolventes orgánicos, herbicidas, pesticidas y eliminar el mal sabor y mal olor del agua. Pero es importante tener presente que los filtros de carbón activado no quitan las bacterias, virus u hongos, ni esporas de hongos del agua.'
            },
            {
                type: 'h2',
                content: 'PRESENTACIONES EN:'
            },
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: '1 PIE3'
                    },
                    {
                        type: 'li',
                        content: '2 PIES3'
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // FILTRO AUTOMATICO ABLANDADOR
    {
        id: 'filtro-automatico-ablandador',
        title: 'FILTRO AUTOMATICO ABLANDADOR',
        subtitle: 'Tratamiento de agua | Línea industrial',
        category: 'agua',           // aire
        line: 'industrial',         // industrial
        images: {
            image1: img_industrial_agua_filtro_automatico_ablandador,
            image2: img_industrial_agua_filtro_automatico_ablandador,
        },
        description: [
            {
                type: 'p',
                content: 'Un ablandador de agua es un equipo que se utiliza para eliminar la dureza del agua, eliminando los minerales que hacen a dicha agua ser dura.'
            },
            {
                type: 'p',
                content: 'El ablandador de agua utiliza la resina catiónica para realizar esta labor de ablandamiento. El agua dura, con calcio y magnesio, corre a través de esta resina, y en un proceso denominado «intercambio iónico», los iones duros del agua intercambian sus posiciones con los iones de sodio que se encuentran en las cuencas de resina. El resultado es un agua blanda.'
            },
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: '1 PIE3'
                    },
                    {
                        type: 'li',
                        content: '2 PIES3'
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // OSMOSIS INVERSA DE 500 LIT / HR
    {
        id: 'osmosis-inversa-de-500-lit-hr',
        title: 'OSMOSIS INVERSA DE 500 LIT / HR',
        subtitle: 'Tratamiento de agua | Línea industrial',
        category: 'agua',           // aire
        line: 'industrial',         // industrial
        images: {
            image1: img_industrial_agua_osmosis_inversa,
            image2: img_industrial_agua_osmosis_inversa,
        },
        description: [
            {
                type: 'p',
                content: 'Con la ósmosis inversa es eliminado un altísimo porcentaje de impurezas, bacterias y microorganismos presentes en el agua, que por este procedimiento son separadas, quedando retenidas y expulsadas por el torrente de rechazo. Este método nos permite disfrutar de un agua más pura, clara y de una gran calidad, para cualquier tipo de consumo humano (beber, preparar alimentos, etc.). Este sistema de membranas consigue eliminar en gran parte los niveles de metales pesados y sales minerales, principalmente del plomo, calcio, magnesio, sodio y nitratos perjudiciales para la salud, Asimismo, la ósmosis inversa filtra las partículas disueltas en el agua y mejora la composición causante de los olores y sabores.'
            },
            {
                type: 'h2',
                content: 'PRESENTACIONES EN:'
            },
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: '250 LIT/HR CON 1 MEMBRANA DE 4X40”'
                    },
                    {
                        type: 'li',
                        content: '500 LIT/HR CON 2 MEMBRANAS DE 4X40”'
                    },
                    {
                        type: 'li',
                        content: '750 LIT/HR CON 3 MEMBRANAS DE 4X40”'
                    },
                    {
                        type: 'li',
                        content: '1000 LIT/HR CON 4 MEMBRANAS DE 4X40”'
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // FILTROS PULIDORES
    {
        id: 'filtros-pulidores',
        title: 'FILTROS PULIDORES',
        subtitle: 'Tratamiento de agua | Línea industrial',
        category: 'agua',           // aire
        line: 'industrial',         // industrial
        images: {
            image1: img_industrial_agua_filtros_pulidores,
            image2: img_industrial_agua_filtros_pulidores,
        },
        description: [
            {
                type: 'h2',
                content: 'DESCRIPCION'
            },
            {
                type: 'p',
                content: 'Los filtros de cartucho pulidores realizan una gran función en la etapa de pre filtración, en múltiples aplicaciones. El filtro pulidor garantiza que ninguna partícula mayor a 1 micra o 5 micras, según sea el requerimiento, llegue al producto o proceso; asimismo, este filtro hace que el agua tenga una apariencia más clara y brillante.'
            },
            {
                type: 'p',
                content: 'Estos filtros son utilizados también para el pre filtración en los sistemas de Ósmosis Inversa como en otros sistemas de purificación de agua.'
            },
            {
                type: 'h2',
                content: 'PRESENTACIONES EN:'
            },
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'FILTROS PULIDOR DE 2.5×10” 1-5-10 MIC – HYDRONIX'
                    },
                    {
                        type: 'li',
                        content: 'FILTROS PULIDOR DE 2.5×20” 1-5-10 MIC – HYDRONIX'
                    },
                    {
                        type: 'li',
                        content: 'FILTROS PULIDOR DE 4.5×20” 1-5-10 MIC – HYDRONIX'
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // ULTRAFILTRACION
    {
        id: 'ultrafiltracion',
        title: 'ULTRAFILTRACION',
        subtitle: 'Tratamiento de agua | Línea industrial',
        category: 'agua',           // aire
        line: 'industrial',         // industrial
        images: {
            image1: img_industrial_agua_ultrafiltracion,
            image2: img_industrial_agua_ultrafiltracion,
        },
        description: [
            {
                type: 'h2',
                content: 'DESCRIPCION'
            },
            {
                type: 'p',
                content: 'Este sistema utiliza membranas de fibra hueca, que es una tecnología desarrollada para diálisis renal. Estas fibras huecas constan de pequeños tubos por donde fluye el agua interiormente, que luego a través de micro poros, de 0.01 micras de diámetro, el agua la atraviesa, dejando atrás infinidad de microorganismos patógenos entre ellos bacterias dañinas como las que causan el cólera, la tifoidea, la escherichia coli y los protozoarios o quistes.'
            },
            {
                type: 'p',
                content: 'Asimismo este equipo remueve los sedimentos del agua dejando el grado de turbidez muy por debajo del permitido.'
            },
            {
                type: 'h2',
                content: 'PRESENTACIONES EN:'
            },
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: '1000 LIT/HR'
                    },
                    {
                        type: 'li',
                        content: '2000 LIT/HR'
                    },
                    {
                        type: 'li',
                        content: '4000 LIT/HR'
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // ULTRAVIOLETA
    {
        id: 'ultravioleta',
        title: 'ULTRAVIOLETA',
        subtitle: 'Tratamiento de agua | Línea industrial',
        category: 'agua',           // aire
        line: 'industrial',         // industrial
        images: {
            image1: img_industrial_agua_ultravioleta,
            image2: img_industrial_agua_ultravioleta,
        },
        description: [
            {
                type: 'h2',
                content: 'DESCRIPCION'
            },
            {
                type: 'p',
                content: 'La lámpara ultravioleta es un equipo avanzado de desinfección de agua. Los rayos ultravioleta UV alteran el ADN de los microorganismos (virus, bacterias, protozoos, etc.) presentes en el agua, eliminándolos, o inactivándolos genéticamente, para impedir su reproducción. La eficiencia del UV en la eliminación de virus y bacterias es del 99.9%; además, el UV es más efectivo que el cloro contra los virus y protozoos.'
            },
            {
                type: 'h2',
                content: 'PRESENTACIONES EN:'
            },
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: '500 LIT/HR DE LA MARCA VIQUA'
                    },
                    {
                        type: 'li',
                        content: '1000 LIT/HR DE LA MARCA VIQUA'
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // GENERADOR DE OZONO
    {
        id: 'generador-de-ozono-agua',
        title: 'GENERADOR DE OZONO',
        subtitle: 'Tratamiento de agua | Línea industrial',
        category: 'agua',           // aire
        line: 'industrial',         // industrial
        images: {
            image1: img_industrial_agua_generador_de_ozono,
            image2: img_industrial_agua_generador_de_ozono,
        },
        description: [
            {
                type: 'h2',
                content: 'DESCRIPCION'
            },
            {
                type: 'p',
                content: 'El ozono es un oxidante muy enérgico y es típicamente utilizado como tal en la desinfección y preservación del agua, está comprobada su eficacia en oxidación de materias orgánicas e inorgánicas (entre éstas últimas destacan el hierro y manganeso). Su poder oxidante y desinfectante, mayor que el del cloro, le hace más eficaz que éste en la eliminación del mal olor, mal sabor y color del agua, así como en la eliminación de algas, bacterias, virus y otros microorganismos.'
            },
            {
                type: 'p',
                content: 'Se puede utilizar tanto para el tratamiento del agua como del aire.'
            },
            {
                type: 'h2',
                content: 'PRESENTACIONES EN:'
            },
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: '3 GRAMOS/HR'
                    },
                    {
                        type: 'li',
                        content: '5 GRAMOS/HR'
                    },
                    {
                        type: 'li',
                        content: '8 GRAMOS/HR'
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // GENERADOR DE OZONO
    {
        id: 'generador-de-ozono-aire',
        title: 'GENERADOR DE OZONO',
        subtitle: 'Tratamiento de aire | Línea industrial',
        category: 'aire',           // aire
        line: 'industrial',         // industrial
        images: {
            image1: img_industrial_agua_generador_de_ozono,
            image2: img_industrial_agua_generador_de_ozono,
        },
        description: [
            {
                type: 'p',
                content: 'El ozono es un oxidante muy enérgico y es típicamente utilizado como tal en la desinfección y preservación del agua, está comprobada su eficacia en oxidación de materias orgánicas e inorgánicas (entre éstas últimas destacan el hierro y manganeso). Su poder oxidante y desinfectante, mayor que el del cloro, le hace más eficaz que éste en la eliminación del mal olor, mal sabor y color del agua, así como en la eliminación de algas, bacterias, virus y otros microorganismos.'
            },
            {
                type: 'p',
                content: 'Se puede utilizar tanto para el tratamiento del agua como del aire.'
            },
            {
                type: 'h2',
                content: 'PRESENTACIONES EN:'
            },
            {
                type: 'li',
                content: '3 GRS'
            },
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: '3 GRAMOS/HR'
                    },
                    {
                        type: 'li',
                        content: '5 GRS.'
                    },
                    {
                        type: 'li',
                        content: '8 GRS'
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // CAÑON DE OZONO
    {
        id: 'cañon-de-ozono',
        title: 'CAÑON DE OZONO',
        subtitle: 'Tratamiento de aire | Línea industrial',
        category: 'aire',           // aire
        line: 'industrial',         // industrial
        images: {
            image1: img_industrial_aire_canon_de_ozono,
            image2: img_industrial_aire_canon_de_ozono,
        },
        description: [
            {
                type: 'p',
                content: 'Este tipo de generadores de Ozono portátiles son  usados para tratamientos de choque por el personal de limpieza de hoteles, empresas de limpieza y centros de lavado a mano de carros y para la desinfección y eliminación de todo tipo de olores después del procedimiento de limpieza habitual.'
            },
            {
                type: 'h2',
                content: 'EL  CAÑON DE OZONO NOS PERMITE:'
            },
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'Eliminar completamente todo tipo de olores.'
                    },
                    {
                        type: 'li',
                        content: 'Desinfectar los ambientes eliminando virus, bacterias, hongos, ácaros, e incluso larvas y huevos de insectos.'
                    },
                    {
                        type: 'li',
                        content: 'Eliminar todo tipo de mico organismo y olor en prendas y tejidos.'
                    },
                    {
                        type: 'li',
                        content: 'Reducir los síntomas del asma y alergias.'
                    },
                    {
                        type: 'li',
                        content: 'Esterilizar ambientes poco ventilados.'
                    },
                    {
                        type: 'li',
                        content: 'Eliminar los olores que dejan las mascotas.'
                    },
                    {
                        type: 'li',
                        content: 'Mejorar la calidad del aire que respiramos.'
                    },
                    {
                        type: 'li',
                        content: 'Reducir drásticamente la posibilidad de contagios entre personas de virus como el de la gripe o el SARS-COV-2, reduciendo así las bajas laborales.'
                    }
                ]
            },
            {
                type: 'h2',
                content: 'PRESENTACIONES EN:'
            },
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: '5 GRS'
                    },
                    {
                        type: 'li',
                        content: '10 GRS.'
                    },
                    {
                        type: 'li',
                        content: '20 GRS'
                    }
                ]
            }
        ],
        details: [
        ],
    },
    // ----------------------------------------------------------------------------
    // PURIFICADOR DE AIRE PARA CARRO
    {
        id: 'purificador-de-aire-para-carro',
        title: 'PURIFICADOR DE AIRE PARA CARRO',
        subtitle: 'Tratamiento de aire | Línea industrial',
        category: 'aire',           // aire
        line: 'industrial',         // industrial
        images: {
            image1: img_industrial_aire_purificador_de_aire_para_carro,
            image2: img_industrial_aire_purificador_de_aire_para_carro,
        },
        description: [
            {
                type: 'h2',
                content: 'DESCRIPCION'
            },
            {
                type: 'p',
                content: 'Equipo portátil solar, Ozonizador, ambientador, ionizador, purificador de aire. El mismo que actúa como esterilizador, germicida, filtra y limpia el aire interior del vehículo.'
            },
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'CARBON ACTIVADO.- Filtra las impurezas del polvo y malos olores de la cabina, generados por el funcionamiento del vehículo.'
                    },
                    {
                        type: 'li',
                        content: 'FILTRO HEPA.- Eliminación de hasta el 98% de partículas de polvo mayores a 0,3 micras.'
                    },
                    {
                        type: 'li',
                        content: 'CARBON ACTIVADO DE PANAL.- adsorción eficiente de la mayoría de gases nocivos y eliminación de olores.'
                    },
                    {
                        type: 'li',
                        content: 'NANO-SILVER.- permite eliminar el crecimiento bacteriano.'
                    }
                ]
            }
        ],
        details: [
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'MEDIDAS: 18x15x4.7cm.'
                    },
                    {
                        type: 'li',
                        content: 'VOLTAJE: 5v.'
                    },
                    {
                        type: 'li',
                        content: 'POTENCIA: 2W'
                    },
                    {
                        type: 'li',
                        content: 'DE IONES NEGAT.: 14MILLONES/m3.'
                    },
                    {
                        type: 'li',
                        content: 'RUIDO DE FUNCIONAMIENTO: < 32db.'
                    },
                    {
                        type: 'li',
                        content: 'LUGAR DE APLICACIÓN: EN EL CARRO/EN LA CASA.'
                    },
                    {
                        type: 'li',
                        content: 'PROTECCION DEL PROD.: PROTECCION DE TIEMPO, 1 HORA.'
                    },
                    {
                        type: 'li',
                        content: 'COLOR: NEGRO.'
                    },
                    {
                        type: 'li',
                        content: 'FUNCION ADICIONAL: SOPORTE DE TELEFONO/SOLAR/CARGADOR DE'
                    },
                    {
                        type: 'li',
                        content: 'TIEMPO DE CARGA: DEL CARRO-6HRS/SOLAR 10HRS.'
                    }
                ]
            }
        ],
    },
    // ----------------------------------------------------------------------------
    // NEBULIZADOR
    {
        id: 'nebulizador',
        title: 'NEBULIZADOR',
        subtitle: 'Tratamiento de aire | Línea industrial',
        category: 'aire',           // aire
        line: 'industrial',         // industrial
        images: {
            image1: img_industrial_aire_nebulizador,
            image2: img_industrial_aire_nebulizador,
        },
        description: [
            {
                type: 'h2',
                content: 'DESCRIPCION:'
            },
            {
                type: 'p',
                content: 'Máquina de desinfección, atomizador, esterilizador de ambientes'
            }
        ],
        details: [
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'Máquina de pulverización y desinfección.'
                    },
                    {
                        type: 'li',
                        content: 'Voltaje / Frecuencia: 220V / 60hz.'
                    },
                    {
                        type: 'li',
                        content: 'Potencia: 400 W.'
                    },
                    {
                        type: 'li',
                        content: 'Tamaño de los productos: 23 x 11 x 16 cm.'
                    },
                    {
                        type: 'li',
                        content: 'Peso: 2 kgs.'
                    },
                    {
                        type: 'li',
                        content: 'Esterilización, purificación de metanol, eliminación de olores.'
                    },
                    {
                        type: 'li',
                        content: 'Tasa de esterilización: 99%'
                    },
                    {
                        type: 'li',
                        content: 'Purificación y eliminación de formaldehído: 92.7%'
                    },
                    {
                        type: 'li',
                        content: 'Eliminación de olores: 99.9%'
                    }
                ]
            }
        ],
    },
    // ----------------------------------------------------------------------------
    // ATOMIZADOR
    {
        id: 'atomizador',
        title: 'ATOMIZADOR',
        subtitle: 'Tratamiento de aire | Línea industrial',
        category: 'aire',           // aire
        line: 'industrial',         // industrial
        images: {
            image1: img_industrial_aire_atomizador,
            image2: img_industrial_aire_atomizador,
        },
        description: [
            {
                type: 'h2',
                content: 'DESCRIPCION:'
            },
            {
                type: 'p',
                content: 'Máquina de desinfección, atomizador, esterilizador de ambientes.'
            }
        ],
        details: [
            {
                type: 'ul',
                content: [
                    {
                        type: 'li',
                        content: 'Voltaje / Frecuencia: 220V / 60hz.'
                    },
                    {
                        type: 'li',
                        content: 'Potencia: 900W.'
                    },
                    {
                        type: 'li',
                        content: 'Medidas: 10X10X10 cm.'
                    },
                    {
                        type: 'li',
                        content: 'Peso bruto: 7.00 kgs.'
                    },
                    {
                        type: 'li',
                        content: 'Tipo de Paquete: 1kg/bolsa, 10 bolsas por caja.'
                    },
                    {
                        type: 'li',
                        content: 'Esterilización, purificación de metanol, eliminación de olores.'
                    },
                    {
                        type: 'li',
                        content: 'Tasa de esterilización: 99%'
                    },
                    {
                        type: 'li',
                        content: 'Purificación y eliminación de formaldehído: 92.7%'
                    },
                    {
                        type: 'li',
                        content: 'Eliminación de olores: 99.9%'
                    }
                ]
            }
        ],
    },
];

export default productsData;
