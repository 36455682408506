
import './Home.scss';
import MainSlider from './MainSlider';
import MisionVision from './MisionVision';
import Nosotros from './Nosotros';
import Services from './Services';
import Iso from './Iso';
import Contactos from './Contactos';
import Ubicacion from './Ubicacion';
import Productos from './Productos';
import { useEffect } from 'react';

function Home() {
    useEffect(() => {
        document.title = 'OzonoRai';
    }, []);

    return (
        <div className="Home">

            <MainSlider></MainSlider>
            <MisionVision></MisionVision>
            <Productos></Productos>
            <Nosotros></Nosotros>
            <Services></Services>
            <Iso></Iso>
            <Contactos></Contactos>
            <Ubicacion></Ubicacion>

        </div>
    );
}

export default Home;
