
import './GeneralHead.scss';
import DummyHeader from './DummyHeader';

function GeneralHead(props) {
    return (
        <div className="GeneralHead" style={props.mystyle} >
            <div className='dummy'><DummyHeader></DummyHeader></div>
            <div className="titulos">
                <div>
                    <h1>{props.title}</h1>
                    <h2>{props.subtitle}</h2>
                </div>
            </div>
        </div>
    );
}

export default GeneralHead;
