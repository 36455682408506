
import imgPlanta from '../img-general/productos.webp'
import imgProcess from '../img-general/process2.svg'
import './Planta.scss';

function Planta() {
    return (
        <div className="Planta">
            <div className='theheader'>
                <h2>PLANTA DE PROCESAMIENTO <br /> Y ENVASADO DE AGUA</h2>
            </div>
            <div className='theimage'>
                <img src={imgPlanta} alt="PLANTA DE PROCESAMIENTO Y ENVASADO DE AGUA" />
            </div>
            <div className='theprocessWrapper'>
                <div className='imgprocess'>
                    <div className='description'>
                        <div>
                            <div >
                                <h2>BENEFICIOS DE UNA PLANTA DE AGUA</h2>
                                <ul>
                                    <li>Versatilidad y eficiencia.</li>
                                    <li>Inversion altamente rentable.</li>
                                    <li>Excelente calidad de agua producto.</li>
                                    <li>Te damos la calidad de agua que tu deseas.</li>
                                    <li>Justo al tamaño de tu presupuesto.</li>
                                    <li>Excelente oportunidad de negocio.</li>
                                    <li>Resultados medibles.</li>
                                    <li>Automatizable y facil de manejar.</li>
                                    <li>Manual de control operacional.</li>
                                    <li>Manual de sanitización de todo el sistema.</li>
                                    <li>Con sistemas de seguridad.</li>
                                    <li>Asesoria y respaldo tecnico permanente.</li>
                                    <li>Nuestra experiencia nos respalda.</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='thevector'>
                    <img src={imgProcess} alt="proceso PLANTA DE PROCESAMIENTO" />
                </div>

                <div className='thetext'>
                    <div >
                        <h2>BENEFICIOS DE UNA PLANTA DE AGUA</h2>
                        <ul>
                            <li>Versatilidad y eficiencia.</li>
                            <li>Inversion altamente rentable.</li>
                            <li>Excelente calidad de agua producto.</li>
                            <li>Te damos la calidad de agua que tu deseas.</li>
                            <li>Justo al tamaño de tu presupuesto.</li>
                            <li>Excelente oportunidad de negocio.</li>
                            <li>Resultados medibles.</li>
                            <li>Automatizable y facil de manejar.</li>
                            <li>Manual de control operacional.</li>
                            <li>Manual de sanitización de todo el sistema.</li>
                            <li>Con sistemas de seguridad.</li>
                            <li>Asesoria y respaldo tecnico permanente.</li>
                            <li>Nuestra experiencia nos respalda.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Planta;

