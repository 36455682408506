
import './Productos.scss';
import imgdomestica from '../img-general/linea-domestica.webp';
import imgindustrial from '../img-general/linea-industrial.webp';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

function Productos() {
    useEffect(() => {
        document.title = 'OzonoRai Productos';
    }, []);

    return (
        <div className="Productos">
            <div className="Lineas">
                <Link to='/productos/linea-domestica'>
                    <div>
                        <img src={imgdomestica} alt="linea domestica"></img>
                        <h2>Línea Doméstica</h2>
                    </div>
                </Link>
                <Link to='/productos/linea-industrial'>
                    <div>
                        <img src={imgindustrial} alt="linea industrial"></img>
                        <h2>Línea Industrial</h2>
                    </div>
                </Link>
            </div>

        </div>
    );
}

export default Productos;
