
import './GeneralCard.scss';
import Zoom from 'react-reveal/Zoom';


function GeneralCard(props) {
    return (
        <div className="GeneralCard">
            <Zoom>
            <div>
                <div>
                    <img src={props.imagen} alt="producto ozonorai"></img>
                </div>
                <h2>{props.rotulo}</h2>
            </div>
            </Zoom>
        </div>
    );
}

export default GeneralCard;

