import React, { useEffect } from 'react'
import { Route, useParams, Navigate } from 'react-router-dom';
import productsData from '../productsData';
import img_background_de_agua from '../imgs-productos/background-de-agua.webp';
import img_background_de_aire from '../imgs-productos/background-de-aire.webp';
import GeneralHead from './GeneralHead';
import './Product.scss';
import Botones from './Botones';

const waterStyle = {
    backgroundImage: `url(${img_background_de_agua})`,
    color: 'white'
};
const airStyle = {
    backgroundImage: `url(${img_background_de_aire})`,
    color: '#0D2DA7'
};

function Product() {

    let title;
    let subtitle;
    let imgProducto;
    let myStyle;

    const { productId } = useParams();

    const myProduct = productsData.find(({ id }) => id === productId);

    if (!myProduct) {
        title = 'Producto no disponible';
    }
    else {
        title = myProduct.title;
        subtitle = myProduct.subtitle;
        imgProducto = myProduct.images.image1;

        myStyle = waterStyle;
        if (myProduct.category === 'agua' || myProduct.category === 'aguaaire' || myProduct.category === 'osmosis')
            myStyle = waterStyle;
        else
            myStyle = airStyle;
    }

    useEffect(() => {
        document.title = title;
    }, []);

    if (!myProduct) {
        return (
            <div>
                {}
                <GeneralHead title={title} subtitle='Sorry, but the product was not found' mystyle={waterStyle}></GeneralHead>
            </div>
        )
    }
    else {
        return (
            <div>
                <GeneralHead title={title} subtitle={subtitle} mystyle={myStyle}></GeneralHead>

                <div className='pDetails'>

                    <div className='pWrapper'>
                        <div className='pImage'>
                            <img src={imgProducto} alt={title}></img>
                        </div>

                        <div className='pDescription'>
                            <div>
                                {
                                    myProduct.description.map(item => {
                                        switch (item.type) {
                                            case 'p': return <p>{item.content}</p>;
                                            case 'h1': return <h1>{item.content}</h1>;
                                            case 'h2': return <h2>{item.content}</h2>;
                                            case 'h3': return <h3>{item.content}</h3>;
                                            case 'ul': return <ul>{item.content.map(item => {
                                                switch (item.type) {
                                                    case 'p': return <p>{item.content}</p>;
                                                    case 'li': return <li>{item.content}</li>;
                                                    case 'h1': return <h1>{item.content}</h1>;
                                                    case 'h2': return <h2>{item.content}</h2>;
                                                    case 'h3': return <h3>{item.content}</h3>;
                                                    case 'ul': return <li style={{ listStyle: 'none' }}><ul>{item.content.map(item => (<li>{item.content}</li>))}</ul></li>;
                                                }
                                            })}</ul>;
                                        }
                                    })
                                }
                            </div>

                            <div className='buttons'>
                                <Botones></Botones>
                            </div>

                        </div>

                        <div className='tData'>
                            {(myProduct.details.length > 0) ? <h2>Datos técnicos:</h2> : ''}

                            {
                                myProduct.details.map(item => {
                                    switch (item.type) {
                                        case 'p': return <p>{item.content}</p>;
                                        case 'h1': return <h1>{item.content}</h1>;
                                        case 'h2': return <h2>{item.content}</h2>;
                                        case 'h3': return <h3>{item.content}</h3>;
                                        case 'ul': return <ul>{item.content.map(item => (<li>{item.content}</li>))}</ul>;
                                    }
                                })
                            }
                        </div>

                    </div>

                </div>

            </div>
        )
    }
}

export default Product;

