
import './Googlemaps.scss'

function GoogleMaps(props) {

    return (
            <img src={props.mapa} />
    );
}

export default GoogleMaps;
