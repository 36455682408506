
import './MisionVision.scss';
import logo from '../img-general/logo-ozonorai-flat.svg';
import Zoom from 'react-reveal/Zoom';


function MisionVision() {
  return (
    <div className="MisionVision">

        <div className="MisionVisionContainer">
            <div className="mvgrid">
                <Zoom>
                <div>
                    <h2 className="mision">Misión</h2>
                    <p className="mision">Empresa Peruana dedicada a la distribución y comercialización de purificadores de agua y aire con ozono para uso doméstico e industrial a nivel nacional e internacional.</p>
                </div>
                </Zoom>
                <div>
                    <img src={logo} alt="logo ozonorai"></img>
                </div>
                <Zoom>
                <div>
                    <h2 className="vision">Visión</h2>
                    <p className="vision">Ser en el 2021, la empresa de mayor posicionamiento en el mercado nacional ofertando productos de la mejor calidad, atención esmerada, precios competitivos y con el equipo de ventas mejor capacitado.</p>
                </div>
                </Zoom>
            </div>
        </div>

    </div>
  );
}

export default MisionVision;


