
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DomesticCardStyles from './DomesticCard.elements';

function DomesticCard({rotulo1line, rotulo2line, imagen, backcolor}) {
    return (
        <DomesticCardStyles backcolor={backcolor}>
            
            <div className="imagen">
                <div className="mask"></div>
                <img src={imagen} alt='Producto linea domestica'></img>
            </div>
            <div className="base">
                <h2>{rotulo1line}<br /> {rotulo2line}</h2>
                <div>Ver Productos <div><FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></div> </div>
            </div>

        </DomesticCardStyles>
    );
}

export default DomesticCard;

