
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function Botones() {
    return (
        <div>
            <div className='buttons'>
                <Link to='/contactos'>
                    <div className='consulta'><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>  &nbsp;&nbsp;&nbsp; CONSULTA</div>
                </Link>
                <a href="https://www.visanetlink.pe/pagoseguro/RAICESGLOBALBUSINESS/235188" target="_blank" rel="noreferrer">
                    <div className='pedido'><FontAwesomeIcon icon={faShoppingCart}></FontAwesomeIcon>  &nbsp;&nbsp;&nbsp;PEDIDO</div>
                </a>
            </div>
        </div>
    )
}

export default Botones;

