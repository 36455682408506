
import DomesticCard from "./DomesticCard";
import logoozonorai from "../img-general/logo-ozonorai-vector.svg";
import imgAgua from '../img-general/subcat-purificadores-de-agua.webp'
import imgAire from '../img-general/subcat-purificadores-de-aire.webp'
import imgOsmosis from '../img-general/subcat-equipos-de osmosis-inversa.webp';
import './Ldomestica.scss';
import { Link } from "react-router-dom";
import { useEffect } from "react";

function Ldomestica() {
    useEffect(() => {
        document.title = 'Productos de línea doméstica';
    }, []);

    return (
        <div className="Ldomestica">

            <div className="linea-domestica">
                <div className="rotulo">
                    <img src={logoozonorai} alt="logo ozonorai"></img>
                    <h1>Línea Doméstica</h1>
                </div>
                <div></div>
            </div>

            <div className="cards-container">
                <div className="cards-wrapper">
                    <div>
                        <Link to='./equipos-de-osmosis-inversa'>
                            <DomesticCard imagen={imgOsmosis} rotulo1line='EQUIPOS DE' rotulo2line='OSMOSIS INVERSA' /* backcolor='#BAC9D0' */ />
                        </Link>
                    </div>

                    <div>
                        <Link to='./purificadores-de-agua'>
                            <DomesticCard imagen={imgAgua} rotulo1line='PURIFICADORES' rotulo2line='DE AGUA' /* backcolor='#4ED8D9' */ />
                        </Link>
                    </div>
                    <div>
                        <Link to='./purificadores-de-aire'>
                            <DomesticCard imagen={imgAire} rotulo1line='PURIFICADORES' rotulo2line='DE AIRE' /* backcolor='#0AA7FE' */ />
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Ldomestica;

