
import React, { useState, useLayoutEffect } from 'react'
import logoozonoraiflat from '../img-general/logo-ozonorai-flat.svg'
import './Header.scss'
import { Link, NavLink } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

const active = {
    color: 'lightblue'
};

function Header() {
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);

    const [translucid, setTranslucid] = useState(true);

	useLayoutEffect(() => {
        const changeBackground = (event) => {
            
            if (window.scrollY <= 130) {
                setTranslucid(true);
            }
            else {
                setTranslucid(false);
            }
        }

        window.addEventListener('scroll', changeBackground);

		return () => {
            window.removeEventListener('scroll', changeBackground);
		}
	}, [])

    return (
        <div className={translucid ? 'Header' : 'Header active'}>
            <header>
                <div className="logo">
                    <a href="/" title="Regresar a la página principal">
                        <picture>
                            <source media="" srcSet={logoozonoraiflat}></source>
                            <img src={logoozonoraiflat} loading="eager" alt="OzonoRai Website"></img>
                        </picture>
                    </a>
                </div>

                <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                    <ul className='nav-menu-items' onClick={showSidebar}>
                        <li className='nav-togg'><NavLink to='#' className='close-burger'> <FontAwesomeIcon icon={faTimes} /></NavLink></li>
                        <li className='nav-text'><NavLink to="/inicio"    style={({isActive})=> (isActive ? active : undefined)}>  <span>Inicio</span> </NavLink></li>
                        <li className='nav-text'><NavLink to="/nosotros"  style={({isActive})=> (isActive ? active : undefined)}>  <span>Nosotros</span> </NavLink></li>
                        <li className='nav-text'><NavLink to="/productos" style={({isActive})=> (isActive ? active : undefined)}>  <span>Productos</span> </NavLink>
                            <ul>
                                <li><Link to="/productos/linea-domestica">Línea doméstica</Link></li>
                                <li><Link to="/productos/linea-industrial">Línea industrial</Link></li>
                            </ul>
                        </li>
                        <li className='nav-text'><NavLink to="/servicios" style={({isActive})=> (isActive ? active : undefined)}>  <span>Servicios</span> </NavLink></li>
                        <li className='nav-text'><NavLink to="/contactos" style={({isActive})=> (isActive ? active : undefined)}>  <span>Contactos</span> </NavLink></li>
                        <li className='nav-text'><NavLink to="/ozono-y-salud" style={({isActive})=> (isActive ? active : undefined)}>  <span>Ozono y Salud</span> </NavLink></li>
                    </ul>            
                </nav>
                <div className="burger" ><FontAwesomeIcon icon={faBars} onClick={showSidebar} /></div>
            </header>
        </div>
  );

}

export default Header;


