
import './HeaderBack.scss';

function HeaderBack() {
  return (
        <div className="HeaderBack">
        </div>
  );
}

export default HeaderBack;