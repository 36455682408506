
import './App.scss';
import Footer from './components/Footer';
import Header from './components/Header';
import HeaderBack from './components/HeaderBack';
import Contactos from './components/Contactos';
import Home from './components/Home';
import Ldomestica from './components/Ldomestica';
import Lindustrial from './components/Lindustrial';
import Productos from './components/Productos';
import Nosotros from './components/Nosotros';
import Services from './components/Services';
import ScrollToTop from './ScrollToTop';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Ubicacion from './components/Ubicacion';
import Iso from './components/Iso';
import Planta from './components/Planta';
import ProductsList from './components/ProductsList';
import DummyHeader from './components/DummyHeader';
import Product from './components/Product';

function App() {

    return (
        <div className="App">
            <HeaderBack />
            <BrowserRouter>
                <Header />
                <ScrollToTop />

                <Routes>

                    <Route path="*" element={
                        <>
                            <Navigate to='/' />
                        </>
                    } />

                    <Route path="/ozono-y-salud" element={
                        <>
                            <Home />
                        </>
                    } />

                    <Route path="/contactos" element={
                        <>
                            <DummyHeader />
                            <Contactos />
                            <Ubicacion />
                        </>
                    } />

                    <Route path="/servicios" element={
                        <>
                            <DummyHeader />
                            <Services />
                            <Iso />
                            <Contactos />
                            <Ubicacion />
                        </>
                    } />

                    <Route path="/productos" element={
                        <>
                            <DummyHeader />
                            <Productos />
                            <Iso />
                            <Contactos />
                            <Ubicacion />
                        </>
                    } />

                    <Route path="/nosotros" element={
                        <>
                            <DummyHeader />
                            <Nosotros />
                            <Iso />
                            <Contactos />
                            <Ubicacion />
                        </>
                    } />

                    <Route path="/inicio" element={
                        <>
                            <Home />
                        </>
                    } />

                    <Route path="/" element={
                        <>
                            <Home />
                        </>
                    } />

                    {}

                    <Route path="/productos/linea-domestica" element={
                        <>
                            <Ldomestica />
                            <Iso />
                            <Contactos />
                            <Ubicacion />
                        </>
                    } />

                    <Route path="/productos/linea-industrial" element={
                        <>
                            <Lindustrial />
                            <Iso />
                            <Contactos />
                            <Ubicacion />
                        </>
                    } />

                    <Route path="/productos/linea-industrial/plantas-de-tratamiento-y-envasado-de-agua" element={
                        <>
                            <DummyHeader />
                            <Planta />
                            <Iso />
                            <Contactos />
                            <Ubicacion />
                        </>
                    } />

                    <Route path="/productos/linea-domestica/equipos-de-osmosis-inversa" element={
                        <>
                            <ProductsList line='domestica' category='osmosis' />
                            <Iso />
                            <Contactos />
                            <Ubicacion />
                        </>
                    } />

                    <Route path="/productos/linea-domestica/purificadores-de-agua" element={
                        <>
                            <ProductsList line='domestica' category='agua' />
                            <Iso />
                            <Contactos />
                            <Ubicacion />
                        </>
                    } />

                    <Route path="/productos/linea-domestica/purificadores-de-aire" element={
                        <>
                            <ProductsList line='domestica' category='aire' />
                            <Iso />
                            <Contactos />
                            <Ubicacion />
                        </>
                    } />

                    <Route path="/productos/linea-industrial/tratamiento-de-agua" element={
                        <>
                            <ProductsList line='industrial' category='agua' />
                            <Iso />
                            <Contactos />
                            <Ubicacion />
                        </>
                    } />

                    <Route path="/productos/linea-industrial/tratamiento-de-aire" element={
                        <>
                            <ProductsList line='industrial' category='aire' />
                            <Iso />
                            <Contactos />
                            <Ubicacion />
                        </>
                    } />

                    {}

                    <Route path="/productos/linea-domestica/equipos-de-osmosis-inversa/:productId" element={
                        <>
                            <Product />
                            <Iso />
                            <Contactos />
                            <Ubicacion />
                        </>
                    } />

                    <Route path="/productos/linea-domestica/purificadores-de-agua/:productId" element={
                        <>
                            <Product />
                            <Iso />
                            <Contactos />
                            <Ubicacion />
                        </>
                    } />

                    <Route path="/productos/linea-domestica/purificadores-de-aire/:productId" element={
                        <>
                            <Product />
                            <Iso />
                            <Contactos />
                            <Ubicacion />
                        </>
                    } />

                    <Route path="/productos/linea-industrial/tratamiento-de-agua/:productId" element={
                        <>
                            <Product />
                            <Iso />
                            <Contactos />
                            <Ubicacion />
                        </>
                    } />

                    <Route path="/productos/linea-industrial/tratamiento-de-aire/:productId" element={
                        <>
                            <Product />
                            <Iso />
                            <Contactos />
                            <Ubicacion />
                        </>
                    } />

                </Routes>
                <Footer />
            </BrowserRouter >
        </div >
    );
}

export default App;
