
import styled from 'styled-components';

const DomesticCardStyles = styled.div`
    position: relative;
    top: 0;
    left: 0;
    padding: 0%;
    .imagen {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 5%;
        width: 100%;
        background-color: #E1E6EC;
        // border: 1px solid blue;
        .mask {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            width: 100%;
            display: block;
            height: 0;
            background-color: ${props => props.backcolor||'white'};
            transition: all 0.4s;
            z-index: 5;
        }
        img {
            position: relative;
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            object-position: center;
            object-fit: cover;
            z-index: 10;
        }
        &:before {
            content: "";
            display: block;
            padding-left: 0;
            padding-top: 100%;
        }
    }

    .base {
        position: relative;
        z-index: 15;
        background-color: white;
        // border: 1px dashed black;
        top: -50px;
        width: 90%;
        margin: 0 auto;
        padding: 10px 0px 20px 0px;
        box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2);
        h2 {
            margin: 0px 0 10px 0;
            color: rgb(66, 66, 66);
            font-size: 24px;
            line-height: 1.1;
            text-align: center;
        }
        div {                           // button
            width: 80%;
            margin: auto;
            border: 1px solid rgba(128, 128, 128, 0.5);
            font-size: 18px;
            font-weight: 700;
            color: ${props => props.theme.colors.lightBlue};
            padding: 5px 0;
            transition: all 0.4s;
            text-align: center;
            div {
                display: inline-block;
                background-color: ${props => props.theme.colors.lightBlue};
                width: 24px;
                height: 24px;
                padding: 0;
                color: white;
                border: 0;
                border-radius: 50%;
                transition: all 0.7s;
                text-align: center;
            }
            &:hover  {
                cursor: pointer;
                background-color: ${props => props.theme.colors.lightBlue};
                color: white;
                div {
                    background-color: white;
                    color: ${props => props.theme.colors.lightBlue};
                    transform: translate(5px, 0);
                }
            }
        }
        transition: all 0.7s;

    }
    &:hover .imagen .mask {
        height: 100%;
    }
    &:hover .base {
        top: -25px;
    }
`;

export default DomesticCardStyles;


