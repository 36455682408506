
import logoozono from '../img-general/logo-ozonorai-outer-glow.svg';
import icontwitter from '../img-general/icon-logo-twitter-ozonorai.svg';
import iconfacebook from '../img-general/icon-logo-facebook-ozonorai.svg';

import './Footer.scss';

function Footer() {
  return (
    <div className="Footer">
        <div className="border"></div>
        <div className="footer-wrapper">
            <div className="logo"><img src={logoozono} alt="logo ozonorai"></img></div>
            <div className="bottomText">
                <div className="txt"><p>Empresa Peruana dedicada a la distribución y comercialización de purificadores de agua y aire con ozono para uso doméstico e industrial a nivel nacional e internacional.</p></div>
                <div className="web"><a href="http://ozonoraiperu.com">www.<span>ozonoraiperu</span>.com</a></div>
                <div className="icons">
                    <a href="https://www.facebook.com/ozonorai2020/" target="_blank" rel="noreferrer"><img src={icontwitter} alt="icon twitter"></img></a>
                    <a href="https://www.facebook.com/ozonorai2020/" target="_blank" rel="noreferrer"> <img src={iconfacebook} alt="icon facebook"></img></a>
                </div>
            </div>
        </div>
    </div>
    
  );
}

export default Footer;

