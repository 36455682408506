
import styled from 'styled-components';
import { device } from '../device';
import imgUbicacion from '../img-general/icon-ubicacion.svg';
import imgTelefono from '../img-general/icon-telefono.svg';
import imgCorreo from '../img-general/icon-correo.svg';

const UbicacionStyles = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0bd;
    /* // border: 2px dashed blue; */
    .mapsWrapper {
        width: 100%;
        max-width: ${props => (props.theme.widths.xlwidth)};
        display: flex;
        justify-content: center;
        gap: 50px;
        // align-items: center;
        padding: 30px 30px;
        // border: 1px dashed black;

        @media ${device.tablet} {
            flex-direction: column;
            padding: 20px 20px;
        }
        @media ${device.phone} {
            flex-direction: column;
            padding: 20px 10px;
        }

        .textmap {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;
            
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            
            .map {
                position: relative;
                min-height: 60vh;
                width: 100% !important;                 // without important it doesnt work
                overflow: hidden;
                object-fit: cover;
                object-position: center;;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    position: relative;
                    width: 100%;
                    overflow: hidden;
                    object-fit: cover;
                    object-position: center;
                    border: 1px solid skyblue;
                }
                &::after {
                    content: '';
                    /* padding-top: 100%; */
                }
            }
            
            .text {
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 0;
                width: 100%;
                min-height: 180px;                          // trick
                // border: 1px dashed blue;
                line-height: 1.1;
                h2 {
                    font-size: 36px;;
                    color: #FF0000;
                    @media ${device.phone} {
                        font-size: 30px;
                    }
                }
                ul {
                    //                 position: relative;
                    margin-top: 1rem;
                    font-size: 18px;
                    color: ${props => (props.theme.colors.azulfont)};
                    font-weight: 500;
                    list-style-position: inside;
                    list-style: none;
                    display: grid;
                    row-gap: 0.5rem;
                    li {
                        position: relative;
                        display: grid;
                        grid-template-columns: 0 1fr;
                        grid-gap: 1.75em;
                        align-items: start;
                        &::before {
                            content: "";
                            background-repeat: no-repeat;
                            display: block;
                            margin: 0 auto;
                            width: 30px;
                            height: 21px;
                        }
                        &:nth-child(1)::before {
                            background-image: url(${imgUbicacion});
                            background-size: auto;
                            
                        }
                        &:nth-child(2)::before {
                            background-image: url(${imgTelefono});
                        }
                        &:nth-child(3)::before {
                            background-image: url(${imgCorreo});
                        }
                    }
                }
            }
        }
    }
`;

export default UbicacionStyles;

