
import './Contactos.scss';
import imgwhatsapp from '../img-general/icon-logo-whatsapp.svg';

function Contactos() {
    
    return (
        <div className="Contactos">

            <div className="Contactanos">
                <form>
                    <div>
                        <div>
                            <h2>CONTÁCTANOS</h2>
                            <p>Estamos siempre disponibles para tí</p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <img src={imgwhatsapp} alt="logo whatsapp"></img>
                            <h2>950 951 450</h2>
                        </div>
                    </div>
                    <div><input type="text" name="name" id="name" required placeholder="Nombre"></input></div>
                    <div><input type="email" name="email" id="email" required placeholder="E-mail"></input></div>
                    <div><input type="text" name="empresa" id="empresa" required placeholder="Empresa"></input></div>
                    <div><input type="tel" name="phone" id="phone" required placeholder="Teléfono"></input></div>
                    <div><textarea name="escribenos" id="escribenos" required placeholder="Escríbenos"></textarea></div>
                    <div><input type="submit" name="submit" id="submit" value="ENVIAR"></input></div>

                </form>
            </div>

        </div>
    );
}

export default Contactos;


