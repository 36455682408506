
import { useEffect } from "react";
import { Link } from "react-router-dom";
import logoozonorai from "../img-general/logo-ozonorai-vector.svg";

import './Lindustrial.scss';

function Lindustrial() {
    useEffect(() => {
        document.title = 'Productos de línea industrial';
    }, []);

    return (
        <div className="Lindustrial">

            <div className="linea-industrial">
                <div></div>
                <div className="rotulo">
                    <img src={logoozonorai} alt="logo ozonorai"></img>
                    <h1>Línea Industrial</h1>
                </div>
            </div>

            <div className="tratamientos">
                <div className="agua">
                    <Link to='./tratamiento-de-agua' >
                        <h2>Tratamiento <br />de Agua</h2>
                    </Link>
                </div>
                <div className="aire">
                    <Link to='./tratamiento-de-aire'>
                        <h2>Tratamiento <br />de Aire</h2>
                    </Link>
                </div>
            </div>

            <div className="plantas">
                <div>
                    <Link to='./plantas-de-tratamiento-y-envasado-de-agua'>
                        <h2>Plantas de Tratamiento y <br />Envasado de Agua</h2>
                    </Link>
                </div>
            </div>

        </div>
    );
}

export default Lindustrial;


