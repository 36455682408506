
import './Nosotros.scss';
import imgporque from '../img-general/productos.webp';
import iconcheck from '../img-general/icon-check.svg';
import iconespecializados from '../img-general/icon-especializados.svg';
import iconcalidad from '../img-general/icon-calidad.svg';
import iconcertificaciones from '../img-general/icon-certificacion.svg';
import Zoom from 'react-reveal/Zoom';
import { useEffect } from 'react';

function Nosotros() {
    useEffect(() => {
        document.title = 'OzonoRai Nosotros';
    }, []);

    return (
        <div className="Nosotros">
            <div className="Elegirnos">
                <div className="wrapper">
                    <div>
                        <img className="icon" src={iconcheck} alt="por que elegirnos"></img>
                        <h2>Por qué <br />elegirnos</h2>
                    </div>
                    <div>
                        <img src={imgporque} alt="por que elegirnos"></img>
                    </div>
                </div>
            </div>

            <div className="Especializados">
                <div className="wrapper">
                    <Zoom>
                        <div>
                            <img src={iconespecializados} alt="especializados"></img>
                            <h2>ESPECIALIZADOS</h2>
                            <p>Contamos con mano de obra especializada</p>
                        </div>
                    </Zoom>
                    <Zoom>
                        <div>
                            <img src={iconcalidad} alt="calidad"></img>
                            <h2>CALIDAD</h2>
                            <p>Trabajamos con las mejores marcas del mundo.</p>
                        </div>
                    </Zoom>
                    <Zoom>
                        <div>
                            <img src={iconcertificaciones} alt="certificaciones"></img>
                            <h2>CERTIFICACIONES</h2>
                            <p>Aprobados por la union europea y el estado peruano.</p>
                        </div>
                    </Zoom>
                </div>
            </div>


        </div>
    );
}

export default Nosotros;


