
export const theme = {
    colors: {
        maincolor: '#0071BA',
        azulfont: '#006D9C',
        lightBlue: '#0088E0',
        backGray: '#F9F9F9'
    },
    heights: {
        menu: {
            xlHeaderHeight: '130px',
            lgHeaderHeight: '120px',
            mdHeaderHeight: '110px', 
            smHeaderHeight: '70px'
        }
    },
    widths: {
        xlwidth: '1530px',
        lgwidth: '1200px',
        mdwidth: '1024px',
        smwidth: '768px',
        xswidth: '324px'
    },
    fontSizes: {
        large: '3rem',
        medium: '2rem',
        small: '1rem'
    },
    breakpoints: {
        phone: '576px',
        tablet: '768px',
        desktop: '992px',
        largedesktop: '1366px'
    }
}

