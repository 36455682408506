
import './DummyHeader.scss';
  
function DummyHeader() {
  return (
        <div className="DummyHeader">
        </div>
  );
}

export default DummyHeader;
