
import './Services.scss';
import imgdiseno from '../img-general/ventajas-diseno.webp';
import imgadaptabilidad from '../img-general/ventajas-adaptabilidad.webp';
import imgsoporte from '../img-general/ventajas-soporte-tecnico.webp';
import imgeconomia from '../img-general/ventajas-economia.webp';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import { useEffect } from 'react';

function Services() {
    useEffect(() => {
        document.title = 'OzonoRai';
    }, []);

    return (
        <div className="Services">

            <div className="Ventajas">
                <div className="imgcontainer">
                    <h1>VENTAJAS <br />DE NUESTROS SERVICIOS</h1>
                </div>
            </div>

            <div className="CardWrapper">
                <div className="Card">
                    <div className="theimage">
                        <Slide right>
                            <img src={imgdiseno} alt="diseño"></img>
                        </Slide>
                    </div>
                    <div className="thedescription">
                        <Zoom>
                            <h2>Diseño</h2>
                            <p>Contamos con diseños únicos de sistemas compactos para:</p>
                            <ul>
                                <li>Tratamiento de aguas residuales.</li>
                                <li>Tratamiento de agua potable.</li>
                            </ul>
                        </Zoom>
                    </div>
                </div>

                <div className="Card">
                    <div className="theimage">
                        <Slide right>
                            <img src={imgadaptabilidad} alt="adaptabilidad"></img>
                        </Slide>
                    </div>
                    <div className="thedescription">
                        <Zoom>
                            <h2>Adaptabilidad</h2>
                            <p>Cada una de nuestras soluciones puede ser construida a la medida de sus necesidades y población por demanda.</p>
                        </Zoom>
                    </div>
                </div>

                <div className="Card">
                    <div className="theimage">
                        <Slide right>
                            <img src={imgsoporte} alt="soporte tecnico"></img>
                        </Slide>
                    </div>
                    <div className="thedescription">
                        <Zoom>
                            <h2>Soporte Técnico</h2>
                            <p>Nuestro servicio abarca el correspondiente soporte técnico desde el arranque y puesta en marcha del sistema, así como el entrenamiento a operadores y el servicio de mantenimiento post-venta de los equipos que componen el sistema.</p>
                        </Zoom>
                    </div>
                </div>

                <div className="Card">
                    <div className="theimage">
                        <Slide right>
                            <img src={imgeconomia} alt="economia"></img>
                        </Slide>
                    </div>
                    <div className="thedescription">
                        <Zoom>
                            <h2>Economía</h2>
                            <p>Altamente calificadas y diseñadas para consumir el mínimo de energía.
                            Pueden operar de manera completamente automática para minimizar las horas hombre.
                  Son compactas, ocupan mínimo espacio y son de fácil transporte.</p>
                        </Zoom>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Services;


