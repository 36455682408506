import React, { Component } from "react";
import Slider from "react-slick";
import imgslider1 from '../img-general/slider-1.webp';
import imgslider2 from '../img-general/slider-2.webp';
import imgslider3 from '../img-general/slider-3.webp';
import imgslider4 from '../img-general/slider-4.webp';
import ozonoraivideo from '../videos/ozonoraivideo.mp4'
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import './MainSlider.scss';

const content = [
    {
        title: "Plantas de tratamiento y envasado de agua",
        description: "",
        button: "Read More",
        image: imgslider1,
        user: "Luan Gjokaj",
        userProfile: "https://i.imgur.com/JSW6mEk.webp"
    },
    {
        title: "",
        description: "",
        button: "Discover",
        image: imgslider2,
        user: "Erich Behrens",
        userProfile: "https://i.imgur.com/0Clfnu7.webp"
    },
    {
        title: "",
        description: "",
        button: "Buy now",
        image: imgslider3,
        user: "Bruno Vizovskyy",
        userProfile: "https://i.imgur.com/4KeKvtH.webp"
    },
    {
        title: "",
        description: "",
        button: "Buy now",
        image: imgslider4,
        user: "Bruno Vizovskyy",
        userProfile: "https://i.imgur.com/4KeKvtH.webp"
    }
];

export default class MainSlider extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            speed: 100,
            autoplaySpeed: 2000,
            infinite: true,
            pauseOnHover: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <div className='MainSlider' >
                <div className="sliderWrapper">

                    <Slider ref={c => (this.slider = c)} {...settings}>
                        <div className='mySlide thevideo' >
                            <video controls autoPlay={true} muted={true} >
                                <source src={ozonoraivideo} type="video/mp4" />
                            </video>
                        </div>
                        <div className='mySlide thetitle'>
                            <img src={imgslider1} alt="bla..." />
                            <h1>Plantas de tratamiento y envasado de agua</h1>
                        </div>
                        <div className='mySlide' >
                            <img src={imgslider2} alt="bla..." />
                        </div>
                        <div className='mySlide' >
                            <img src={imgslider3} alt="bla..." />
                        </div>
                        <div className='mySlide' >
                            <img src={imgslider4} alt="bla..." />
                        </div>
                    </Slider>

                </div>

                <div className='buttons' >
                    <button className="previousButton" onClick={this.previous}>
                        Previous
                    </button>
                    <button className="nextButton" onClick={this.next}>
                        Next
                    </button>
                </div>
            </div>
        );
    }
}
