
import { useEffect } from 'react'
import img_background_de_agua from '../imgs-productos/background-de-agua.webp';
import img_background_de_aire from '../imgs-productos/background-de-aire.webp';
import GeneralCard from './GeneralCard';
import GeneralHead from './GeneralHead';
import { Link } from 'react-router-dom';
import productsData from '../productsData';
import './CardsContainer.scss';

const waterStyle = {
    backgroundImage: `url(${img_background_de_agua})`,
    color: 'white'
};
const airStyle = {
    backgroundImage: `url(${img_background_de_aire})`,
    color: '#0D2DA7'
};

let myStyle = waterStyle;

function ProductsList(props) {
    let subtitle = '';
    let title = '';

    if (props.line === 'industrial') {
        switch (props.category) {
            case 'agua': title = 'Tratamiento de Agua'; myStyle = waterStyle; break;
            case 'aire': title = 'Tratamiento de Aire'; myStyle = airStyle; break;
        }
        subtitle = 'Línea Industrial';
    }
    else {
        switch (props.category) {
            case 'agua': title = 'PURIFICADORES DE AGUA'; myStyle = waterStyle; break;
            case 'aire': title = 'PURIFICADORES DE AIRE'; myStyle = airStyle; break;
            case 'osmosis': title = 'EQUIPOS DE OSMOSIS INVERSA'; myStyle = waterStyle; break;
        }
        subtitle = 'Línea Doméstica';
    }

    const lineProducts = productsData.filter(item => item.line === props.line);

    const catProducts = lineProducts.filter(item => {
        if ((props.category === 'agua' || props.category === 'aire') && (item.category === 'aguaaire'))
            return true;
        else if (item.category === props.category)
            return true;
        return false;
    });

    useEffect(() => {
        document.title = title;
    }, []);

    return (
        <div>
            <GeneralHead title={title} subtitle={subtitle} mystyle={myStyle} />
            <div className="CardsContainer">
                <div className="cardsWrapper">
                    {
                        catProducts.map((item) => (
                            <div key={item.id}>
                                <Link to={`./${item.id}`}>
                                    <GeneralCard imagen={item.images.image1} rotulo={item.title} />
                                </Link>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default ProductsList;
