
import './Iso.scss';
import imgiso9001 from '../img-general/iso-9001.webp';

function Iso() {
  return (
    <div className="Iso">
        <div className="Iso9001">
            <div className="isocontainer">
            <img src={imgiso9001} alt="iso 9001"></img>
            </div>
        </div>
    </div>
  );
}

export default Iso;


